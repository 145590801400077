import React from "react"

const CardVideo = ({ video, sub, poster }) => {
  return (
    <div className="mb-8 lg:mb-4">
      <video
        src={video}
        controls
        poster={poster}
        className="rounded-2xl overflow-hidden mb-2"
      ></video>
      <span className="text-gray-400 text-sm">{sub}</span>
    </div>
  )
}

export default CardVideo
