import React, { useEffect, useState } from "react"
import { STATIC_SERVER_VIDEOS } from "../assets/js/_variables";
import CardVideo from "../components/CardVideo";
import Layout from "../components/Layout";

const VideoGallery = () => {
  const es = {
    pageTitle: "Video Galería | HB Privileged",
    mainTitle: "Video Galería",
  }
  const en = {
    pageTitle: "Video Galley | HB Privileged",
    mainTitle: "Video Gallery",
  }

  const [txt, setTxt] = useState(es)

  const getLang = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('lang')
    }
  }

  useEffect(() => {
    if(getLang() === 'es') {
      setTxt(es)
    } else {
      setTxt(en)
    }
  }, [])

  return (
    <Layout pageTitle={txt.pageTitle}>
      <div className="section py-12 md:py-16">
        <h2 className="title mb-8">{txt.mainTitle}</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <CardVideo 
            video={`${STATIC_SERVER_VIDEOS}/Beach%20boy%20Cruise%20-%20Whale%20Watching.mp4`}
            sub="Beach boy Cruise - Whale Watching"
          />
          <CardVideo 
            video={`${STATIC_SERVER_VIDEOS}/Nadar%20con%20delfines%20en%20Puerto%20Vallarta%20con%20Aquaventuras%20Park_1080p.mp4`}
            sub="Nadar con delfines en Puerto Vallarta con Aquaventuras Park"
          />
          <CardVideo 
            video={`${STATIC_SERVER_VIDEOS}/Paseo%20a%20Islas%20Marietas%20por%20Crucero%20BahÃ%C2%ADa%20Alegre.mp4`}
            sub="Paseo a Islas Marietas por Crucero Bahia Alegre"
          />
          <CardVideo 
            video={`${STATIC_SERVER_VIDEOS}/Puerto%20Vallarta%20Electric%20Bikes_1080p.mp4`}
            sub="Puerto Vallarta Electric Bikes"
          />

          <CardVideo 
            video={`${STATIC_SERVER_VIDEOS}/Ritmos%20de%20la%20Noche%20%20Rhythms%20of%20the%20Night%20%20by%20Vallarta%20Adventures_1080p.mp4`}
            sub="Ritmos de la Noche  Rhythms of the Night  by Vallarta Adventures"
          />
          <CardVideo 
            video={`${STATIC_SERVER_VIDEOS}/Sayulita%20%20San%20Pancho%20by%20Vallarta%20Adventures_1080p.mp4`}
            sub="Sayulita  San Pancho by Vallarta Adventures"
          />
          <CardVideo 
            video={`${STATIC_SERVER_VIDEOS}/Tequila,%20Sun,%20Beach%20and%20Hiking.mp4`}
            sub="Tequila, Sun, Beach and Hiking"
          />
          <CardVideo 
            video={`${STATIC_SERVER_VIDEOS}/Zipline%20By%20Canopy%20River%20Puerto%20Vallarta%20Mexico_1080p.mp4`}
            sub="Zipline By Canopy River Puerto Vallarta Mexico"
          />
        </div>
      </div>
    </Layout>
  );
}
 
export default VideoGallery;